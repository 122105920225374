import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/SEO';
import HeadingSection from '../components/HeadingSection';

import imageIllustrationPrivacyPolicy from '../assets/images/illustrations/Privacy_policy.png';

const PrivacyPage = () => (
  <>
    <SEO title="Raffle Terms and Conditions" />
    <div className="common-page privacy-page">
      <HeadingSection h1="Raffle Terms and Conditions" image={imageIllustrationPrivacyPolicy} />
      <SecondSection />
    </div>
  </>
);

const SecondSection = () => (
  <section className="second-section">
    <div className="container">
      <h2>Raffle Terms and Conditions</h2>
      <p>
        <ol>
          <li>
            <p>The promoter is: Sprive Limited (company no. 12102682) whose
            registered office is at Cannon Place, 78 Cannon Street, 
            London, EC4N 6AF.
          </p>
          </li>
          <li>
            <p>
              The competition is open to residential homeowners within the
              United Kingdom aged 18 years or over except employees of Sprive
              Limited and their close relatives and anyone otherwise connected
              with the organisation or judging of the competition.
            </p>
          </li>
          <li>
            <p>
              There is no entry fee and no purchase necessary to enter this
              competition.
            </p>
          </li>
          <li>
            <p>
              By entering this competition, an entrant is indicating his/her
              agreement to be bound by these terms and conditions.
            </p>
          </li>
          <li>
            <p>Only one entry will be accepted per person. Multiple entries from
the same person will be disqualified.</p>
          </li>
          <li>
            <p>Closing date for entry will be upon Sprive receiving a 1,000 email
            sign ups on the Sprive waiting list. After which no further entries to
the competition will be permitted.</p>
          </li>
          <li>
            <p>No responsibility can be accepted for entries not received for
whatever reason.</p>
          </li>
          <li>
            <p>The rules of the competition and how to enter are as follows:
              <ul>
                <li>Visit <a
        href="https://sprive.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        sprive.com
        </a></li>
                <li>Enter your email address and let us know who your residential
            mortgage lender is with</li>
            <li>Once we have received a 1,000 sign-ups, they will be entered into a
raffle draw to win a £100 amazon voucher</li>
              </ul>
            </p>
          </li>
          <li>
            <p>The promoter reserves the right to cancel or amend the
            competition and these terms and conditions without notice in the
            event of a catastrophe, war, civil or military disturbance, act of God or
            any actual or anticipated breach of any applicable law or regulation or
            any other event outside of the promoter’s control. Any changes to the
            competition will be notified to entrants as soon as possible by the
promoter.</p>
          </li>
          <li>
            <p>The promoter is not responsible for inaccurate prize details
            supplied to any entrant by any third party connected with this
competition.</p>
          </li>
          <li>
            <p>The prize is as follows:
            The prize is as stated and no cash or other alternatives will be offered.
            The prizes are not transferable. Prizes are subject to availability and
            we reserve the right to substitute any prize with another of equivalent
value without giving notice.</p>
          </li>
          <li>
            <p>Winners will be chosen:
            at random by software, from all entries received and verified by
            Promoter and or its agents only once a 1,000 email sign ups have been
reached.</p>
          </li>
          <li>
            <p>The winner will be notified by email and/or DM on Twitter. If the
            winner cannot be contacted or do not claim the prize within 14 days
            of notification, we reserve the right to withdraw the prize from the
winner and pick a replacement winner.</p>
          </li>
          <li>
            <p>The promoter will notify the winner how the prize can be
collected.</p>
          </li>
          <li>
            <p>The promoter’s decision in respect of all matters to do with the
competition will be final and no correspondence will be entered into.</p>
          </li>
          <li>
            <p>By entering this competition, an entrant is indicating his/her
agreement to be bound by these terms and conditions.</p>
          </li>
          <li>
            <p>The competition and these terms and conditions will be governed
            by English law and any disputes will be subject to the exclusive
jurisdiction of the courts of England.</p>
          </li>
          <li>
            <p>The winner agrees to the use of his/her name and image in any
            publicity material, as well as their entry. Any personal data relating to
            the winner or any other entrants will be used solely in accordance
            with current UK data protection legislation and will not be disclosed to
a third party without the entrant’s prior consent.</p>
          </li>
          <li>
            <p>The winner’s name will be available 28 days after closing date by
emailing the following address <a href="mailto:hello@sprive.com" type="email">
        hello@sprive.com
          </a>.</p>
          </li>
          <li>
            <p>Entry into the competition will be deemed as acceptance of these
terms and conditions.</p>
          </li>
          <li>
            <p>This promotion is in no way sponsored, endorsed or administered
            by, or associated with, Facebook, Twitter or any other Social Network.
            You are providing your information to Sprive Limited and not to any
            other party. The information provided will be used in conjunction with
the following Privacy Policy found at <Link to="/privacy">https://sprive.com/privacy</Link>.
            </p>
          </li>
        </ol>
      </p>
    </div>
  </section>
);

export default PrivacyPage;
